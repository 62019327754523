import React from 'react'
import Topnav from './Topnav'
import Header from './Header'
import Footer from './Footer'

function Base(props) {
  return (
    <div>
        {/* <Topnav/> */}
        {/* <Header/> */}
        {props.children}
        <Footer/>

        
    </div>
  )
}

export default Base