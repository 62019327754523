import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {BsArrowLeft} from "react-icons/bs"

function Blogpage() {
  const {state} = useLocation()
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top when the component mounts or when the location changes (navigation)
    window.scrollTo(0, 0);
  }, [state]);
  const backToHome = () => {
    navigate("/");
  };
  return (
    <div className="blogpage">
      <div className="container my-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-7 text-start">
            <div>
              <button className="btn btn-primary" onClick={backToHome}>
               <BsArrowLeft size={'22px'}/>&nbsp;
                Back
              </button>
            </div>
            <br />
            <div>
              <h1>{state.title}</h1>
            </div>
            <div>
              <img src={state.image} alt="blog1" className="img=fluid" />
            </div>
            <br />
            <div>
              {state.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogpage;
