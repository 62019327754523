import React from "react";
import { BsCheck } from "react-icons/bs";
function Fee() {
  return (
    <div className="fee" id="fees">
      <div className="container text-center py-5">
        <div className="row text-center py-3">
          <div className="ab">
            <span>OUR FEE</span>
          </div>
          <div>
            <h1>Choose a fee plan</h1>
          </div>
          <div>
            <p>
              At Towards Quran, we offer competitive fees for our
              Quranic courses, making quality education accessible to all.
              Choose from our flexible payment options, including monthly,
              quarterly, or annual plans. Experience our teaching with free
              trial classes and start your Quranic journey affordably."
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 ">
            <div class="card text-center pb-5 fee-card">
              <div className="text-start card-title p-2 ">
                <div>
                  <h6>
                    <strong>BASIC</strong>
                  </h6>
                </div>
                <div>
                  <div className="d-flex">
                    <h1>$50</h1>
                    <div className="mt-4">
                      <strong>/Month</strong>
                    </div>
                  </div>
                  <div>
                    <strong>Basic Quranic Learning</strong>
                  </div>
                </div>
              </div>
              <hr />
              <div className="p-3 card-body">
                <p className="text-start">
                  Access to our foundational courses, including Noorani Qaida
                  and basic Quranic recitation.
                </p>
                <div>
                  <ul className="text-start list-unstyled">
                    <li className="d-flex align-items-center mb-3">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Five one-on-one sessions per week with an experienced
                          Quran tutor.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Learning materials and resources provided.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Progress tracking and assessment.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-footer">
                <button className="btn btn1 btn-success">Get Started</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div class="card  text-center pb-5 fee-card">
              <div className="text-start card-title p-2 ">
                <div>
                  <h6>
                    <strong>ADVANCE</strong>
                  </h6>
                </div>
                <div>
                  <div className="d-flex">
                    <h1>$100</h1>
                    <div className="mt-4">
                      <strong>/Month</strong>
                    </div>
                  </div>
                  <div>
                    <strong>Comprehensive Quranic Education</strong>
                  </div>
                </div>
              </div>
              <hr />
              <div className="p-3 card-body">
                <p className="text-start">
                  Comprehensive access to a wide range of Quranic courses,
                  including Tajweed, Tafsir, and more.
                </p>
                <div>
                  <ul className="text-start list-unstyled">
                    <li className="d-flex align-items-center mb-3">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Ten one-on-one sessions per week with dedicated
                          Quranic instructors.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Customized learning plans to meet your specific goals.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Access to our library of supplementary resources.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Regular progress evaluations and feedback.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-footer">
                <div>
                  <button className="btn btn1 btn-success">Get Started</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div class="card text-center pb-5 fee-card">
              <div className="text-start card-title p-2 ">
                <div>
                  <h6>
                    <strong>ENTERPRISE</strong>
                  </h6>
                </div>
                <div>
                  <div className="d-flex">
                    <h1>$150</h1>
                    <div className="mt-4">
                      <strong>/Month</strong>
                    </div>
                  </div>
                  <div>
                    <strong>Hifz and Advanced Quran Studies</strong>
                  </div>
                </div>
              </div>
              <hr />
              <div className="p-3 card-body">
                <p className="text-start">
                  Access to advanced Quranic studies, including Hifz
                  (memorization) courses.
                </p>
                <div>
                  <ul className="text-start list-unstyled">
                    <li className="d-flex align-items-center mb-3">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Fifteen one-on-one sessions per week with Hifz
                          specialists and advanced Quran teachers.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Personalized memorization support and guidance.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Extensive access to our Quranic and Islamic knowledge
                          resources.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <BsCheck size={"30px"} color="#157815" className="me-2" />
                      <div>
                        <p className="mb-0">
                          Continuous assessment and mentoring for Hifz students.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-footer">
                <button className="btn btn1 btn-success">Get Started</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fee;
