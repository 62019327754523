import React from 'react'

function Students() {
  return (
    <div className='students'>
         <div className="container pt-5 text-center">
            <div className="row pt-5">
                <div className="col-md-3 ">
                    <div>
                        {/* <img src="/Assets/blackcircle.png" alt="blackcircle" /> */}
                    </div>
                    <div>
                        <h1>34+</h1>
                    </div>
                    <div>
                        <p>Happy Students</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div>
                        {/* <img src="/Assets/blackcircle.png" alt="blackcircle" /> */}
                    </div>
                    <div>
                        <h1>10+</h1>
                    </div>
                    <div>
                        <p>Professional Teachers</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div>
                        {/* <img src="/Assets/blackcircle.png" alt="blackcircle" /> */}
                    </div>
                    <div>
                        <h1>2+</h1>
                    </div>
                    <div>
                        <p>Countries</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div>
                        {/* <img src="/Assets/blackcircle.png" alt="blackcircle" /> */}
                    </div>
                    <div>
                        <h1>6+</h1>
                    </div>
                    <div>
                        <p>Years of Experience</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Students