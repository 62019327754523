import React from "react";

function About() {
  return (
    <div className="about" id="abouts">
      <div className="container py-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5 mb-3">
            <div className="ab">
              <span>About</span>
            </div>
            <br />
            <div>
              <h1>Best Online Quran Academy</h1>
            </div>
            <div>
             <p > At Towards Quran, we're dedicated to providing
              comprehensive online Quranic education services. Our courses cover
              Nazra, Qaida, Hifz, Tajweed, and more. With a commitment to
              quality, we ensure that students of all levels receive the best
              Quranic instruction. Additionally, we have two knowledgeable
              Muftis available to address any questions or concerns related to
              Islamic topics and masaail. Join us on a journey of Quranic
              learning and spiritual growth.</p>
            </div>
            <br />
            <div>
              <button className="btn contact">CONTACT US</button>
            </div>
          </div>
          <div className="col-md-5 mb-3">
            <img src="Assets/a1.png" alt="q1" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
