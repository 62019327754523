import React from "react";
import { useState } from "react";
import Topnav from "../Layout/Topnav";
// import PhoneInput from "react-phone-number-input";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function Contactus() {
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    // Use a regular expression to check if the input is numeric
    if (/^\d*$/.test(input)) {
      setPhone(input);
    }
  };
  return (
    <>
      <Topnav />
      <div className="contactus" >
        <div className="container py-4">
          <div className="row">
            <div>
              <button className="btn" onClick={backToHome}>
                <BsArrowLeft size={"22px"} />
                &nbsp; Back
              </button>{" "}
            </div>
            <br /> <br />
          </div>
          <br />
          <div className="row">
            <div className="col-md-6 text-start">
              <div>
                <img src="Assets/a1.png" alt="q1" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 pt-5 text-start">
              <div className="card p-4 ">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput">First Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                  />
                  <label for="floatingPassword">Last Name</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="int"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="Phone Number"
                    onChange={handleInputChange}
                    value={phone}
                    pattern="[0-9]*"
                  />
                  <label for="floatingPassword">Phone Number</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    id="floatingTextarea"
                  ></textarea>
                  <label for="floatingTextarea">Comments</label>
                </div>
                <div className="form-floating mb-3">
                  <div>
                  <button className="btn  form-control">
                    Submit
                  </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactus;
