import React from 'react'
import Mobnav from '../Components/Layout/Mobnav'
import Hero from '../Components/Elements/Hero'
import Professional from '../Components/Elements/Professional'
import About from '../Components/Elements/About'
import Services from '../Components/Elements/Services'
import Students from '../Components/Elements/Students'
import Fee from '../Components/Elements/Fee'
import Step from '../Components/Elements/Step'
import Clients from '../Components/Elements/Clients'
import Blog from '../Components/Elements/Blog'
import Learning from '../Components/Elements/Learning'
import Header from '../Components/Layout/Header'

function Home() {
  return (
    <div>
    <Mobnav/>
    <Header/>
    <Hero/>
    <Professional/>
    <About/>
    <Services/>
    <Students/>
    <Fee/>
    <Step/>
    <Clients/>
    <Blog/>
    <Learning/>
    </div>
  )
}

export default Home