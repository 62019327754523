import React from "react";

function Step() {
  return (
    <div className="step">
      <div className="container py-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5 mb-3">
            <img src="Assets/step1.png" alt="q2" className="img-fluid" />
          </div>
          <div className="col-md-5 mb-3">
            <div className="ab">
              <span>STEPS TO LEARN</span>
            </div> <br />
            <div>
              <h2>Begin Learning Quran Now in 3 Easy Steps</h2>
            </div>
            <div>
            Get started on your Quranic journey with just three simple steps.
We've made it easy to begin your Quranic education.
No hassles, no delays—start learning the Quran today.
            </div>
            <br />
            <div className="d-flex gap-3">
              <div>
                <img src="Assets/span.png" alt="circle" className="img-fluid" width={"95px"}/>
              </div>
              <div>
                <div>
                  <h6>Click on Register Online</h6>
                </div>
                <div>
                  <p>
                  Registering online is quick and convenient.
Secure your spot in our Quranic courses with just a few clicks.
Join our community of Quran learners by registering online.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3">
              <div>
                <img src="Assets/skype.png" alt="circle" className="img-fluid" width={"95px"}/>
              </div>
              <div>
                <div>
                  <h6>Pick a Time for Free Trial:</h6>
                </div>
                <div>
                  <p>
                  Try out our classes with a free trial session.
Choose a time that suits your schedule for your trial class.
Experience the quality of our Quranic instruction firsthand.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3">
              <div>
                <img src="Assets/qq.png" alt="circle" className="img-fluid" width={"120px"}/>
              </div>
              <div>
                <div>
                  <h6>Embark On this Holy Journey with your 1st Class:</h6>
                </div>
                <div>
                  <p>
                  Your first class marks the beginning of your sacred Quranic journey.
Our experienced instructors will guide you from day one.
Prepare to dive into the beauty and wisdom of the Quran in your very first class.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step;
