import React from "react";

function Professional() {
  return (
    <div className="professional">
      <div className="container py-5 text-center">
        <div className="row  d-flex justify-content-center">
          <div className="col-md-4">
            <div>
              <img src="Assets/p1.png" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>Professional Teachers</h5>
            </div>
            <div>
              <p>
                {/* Meet Our Dedicated Instructors <br /> */}
                Our instructors at Towards Quran are experienced, passionate,
                and dedicated to your Quranic education. Discover the wisdom
                they bring to each class.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img src="Assets/p2.png" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>Online Classes</h5>
            </div>
            <div>
              <p>
                Access high-quality Quranic education from anywhere with our
                online classes. Join us online and start your Quranic journey
                today.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img src="Assets/p3.png" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>Female Teachers</h5>
            </div>
            <div>
              <p>
                We're proud to have a team of expert female instructors who
                provide a supportive and empowering learning experience. Connect
                with the Quran in a respectful and inclusive setting.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Professional;
