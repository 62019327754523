import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
function Mobnav() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const closeNav = () => {
    setIsNavOpen(false);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <div className='mobnav'>
        <nav class="navbar navbar-dark bg-dark ">
  <div class="container-fluid">
    
    <button class="navbar-toggler" type="button"  onClick={toggleNav} data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class={`offcanvas offcanvas-start text-bg-dark ${isNavOpen ? 'show' : ''}`} tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">QURAN For All</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
        {/* <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#hero">Home</a>
        </li> */}
        <li className="nav-item">
          <a className="nav-link active"  onClick={closeNav} aria-current="page" href="#abouts">About Us</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle active" onClick={closeNav} href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Services
          </a>
         
        </li>
        <li className="nav-item">
          <a className="nav-link active" onClick={closeNav} aria-current="page" href="#fees">Fee</a>
        </li>
        <li className="nav-item">
          <Link to='/download'>
          <a className="nav-link active" aria-current="page" onClick={closeNav} >
            Download
            </a>
          </Link>
        </li>
        <li className="nav-item">
          <a className="nav-link active" onClick={closeNav} aria-current="page" href="#blog">Blogs</a>
        </li>
        <li className="nav-item">
          <Link to='/contactus'>
          <a className="nav-link active" onClick={closeNav} aria-current="page" href="/">Contact</a>
          </Link>
        </li>
         
        </ul>
       
      </div>
    </div>
    <div className='d-flex gap-4 quran' >
        <div>
        <a className="nav-link active " aria-current="page" href="#hero"> <img
              src="/Assets/logo.svg"
              alt="quran"
              className="img-fluid"
              style={{width:"150px"}}
            /></a>
        </div>
    </div>
  </div>
</nav>
    </div>
  )
}

export default Mobnav