import React from 'react'
import Blogpage from '../Components/Elements/Blogpage'
import Blog from '../Components/Elements/Blog'
import Topnav from '../Components/Layout/Topnav'

function Blogs() {
  return (
    <div>
      <Topnav/>
        <Blogpage/>
        <Blog/>
    </div>
  )
}

export default Blogs