import React from "react";

function Hero() {
  return (
    <div className="hero" id="hero">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-5 quran ">
            <div className="ab">
              <span>ONLINE QURAN ACADEMY</span>
            </div> <br />
            <div>
              <h2>"Nurturing Hearts and Minds Through Quranic Wisdom"</h2>
            </div>
            <div>
             <p> At Towards Quran, we are passionate about nurturing a
              love for the Quran and Islamic knowledge. Our team of experienced
              instructors is committed to providing high-quality education in a
              supportive and inclusive environment.</p>
            </div>
            <br />
            <div className="d-flex gap-4 ">
              <div>
                <button className="btn btn1 contact">CONTACT US</button>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
