import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Base from './Components/Layout/Base';
import Home from './Pages/Home';
import Blogs from './Pages/Blogs'
import Download from './Components/Elements/Download';
import { ToastContainer } from 'react-toastify';
import Contactus from './Components/Elements/Contactus';

function App() {
  return (
    < >
    <ToastContainer/>
<BrowserRouter>
<Base>
<Routes>
  <Route index path='/' element={<Home/>}/>
  <Route  path='/blogpage' element={<Blogs/>}/>
  <Route  path='/download' element={<Download/>}/>
  <Route  path='/contactus' element={<Contactus/>}/>
</Routes>
</Base>
</BrowserRouter>
   
    </>
  );
}

export default App;
