import React, { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import Quran from "../Utils/Quran.json";
import { toast } from "react-toastify";
import Topnav from '../Layout/Topnav'
function Download() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };
  const pdfDownload = (title) => {
    toast.success(`${title} PDF download was successful!`);
  };
  useEffect(() => {
    // Scroll to the top when the component mounts or when the location changes (navigation)
    window.scrollTo(0, 0);
  }, [state]);
  return (
    <>
    <Topnav/>
    <div className="download">
      <div className="container py-3">
        <div className="row  d-flex justify-content-center">
          <div className="col-md-12">
            <button className="btn" onClick={backToHome}>
              <BsArrowLeft size={"22px"} />
              &nbsp; Back
            </button>{" "}
            <br /> <br />
            <div>
              <h4>
                Explore the comprehensive guidebook from the Quran Academy,
                designed to enhance your understanding of the Holy Quran. This
                PDF provides valuable insights into Tajweed, Tafsir, and
                memorization techniques, making it an invaluable resource for
                both beginners and advanced learners. Elevate your Quranic
                studies with this authoritative guide.
              </h4>
            </div>{" "}
            <br />
            <div>
              <img src="/Assets/d1.png" alt="download" className="img-fluid" />
            </div>
          </div>
        </div>
        <br />
        <div className="row d-flex justify-content-center mb-2">
          {Quran.map((item, index) => (
            <div className="col-md-3 d-flex justify-content-center" key={index}>
              <div className="card mb-2 " style={{ border: "none" }}>
                <div className="position-relative ">
                  <img
                    src={item.img}
                    alt="blog"
                    className="img-fluid "
                    // style={{height:"350px"}}
                  />
                </div>
                <div className="title p-2 text-center">
                  <h5>{item.title}</h5>
                </div>
                <button
                  className="btn btn-success mt-2"
                  onClick={() => pdfDownload(item.title)}
                >
                  <a
                    href={item.quran_pdf}
                    target="_blank"
                    download
                    rel="noopener noreferrer"
                  >
                    Download PDF
                  </a>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
}

export default Download;
