import React from "react";
import { MdOutlineDateRange } from "react-icons/md";
import { BiSolidMessageRounded } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
function Clients() {
  return (
    <div className="clients">
      <div className="container py-5">
        <div className="row text-center py-3">
          <div className="ab">
            <span>WHAT CLIENT SAY?</span>
          </div>
          <div>
            <h1>Quran For All Academy Services</h1>
          </div>
          <div>
            <p>
              Certainly, here are three student reviews with the names Omair,
              Irfan Ahmed, and Arif:
            </p>
          </div>
        </div>
        <div className="row text-center d-flex justify-content-center">
          <div className="col-md-4 mb-3">
            <div class="card">
              <div class="card-body">
                <div className="myimg">
                  <img
                    src="/Assets/client1.jpeg"
                    class="img-fluid"
                    alt="blog1"
                    style={{
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </div>{" "}
                <br />
                <h5 class="card-title">Omair</h5>
                <div>
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                </div>{" "}
                <br />
                <p class="card-text">
                  Enrolling at "Towards Quran" has been a transformative
                  experience for me. The instructors are not only knowledgeable
                  but also incredibly patient and supportive. I've made
                  remarkable progress in my Quranic studies, and I'm deeply
                  grateful for the opportunity to learn here.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div class="card">
              <div class="card-body">
                <div>
                  <img
                    src="/Assets/client2.jpeg"
                    className="img-fluid"
                    alt="blog1"
                    style={{
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </div>{" "}
                <br />
                <h5 class="card-title">Irfan Ahmed</h5>
                <div>
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                </div>
                <br />
                <p class="card-text">
                  I've always wanted to improve my Quranic recitation, and
                  "Towards Quran" has helped me achieve that goal. The
                  personalized attention I receive during my lessons has been
                  invaluable. I highly recommend this academy to anyone seeking
                  to enhance their Quranic knowledge
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div class="card">
              <div class="card-body">
                <div>
                  <img src="/Assets/client3.jpeg" class="img-fluid" alt="blog1" 
                  style={{ borderRadius: "50%" ,width:"70px" , height:"70px" }}
                  />
                </div>{" "}
                <br />
                <h5 class="card-title">Arif</h5>
                <div>
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                  <img src="Assets/star.png" alt="star" className="img-fluid" />
                </div>{" "}
                <br />
                <p class="card-text">
                  As a parent, I wanted the best Quranic education for my child,
                  and I found it at "Towards Quran". The female Quran tutors are
                  exceptional, providing a nurturing and safe environment for my
                  daughter to learn. Her progress has been impressive, and I
                  couldn't be happier with our choice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
