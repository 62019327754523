import React from 'react'

function Services() {
  return (
    <div className='services'>
        <div className="container py-5 text-center">
            <div className="row text-center">
            <div className="ab">
              <span>OUR SERVICES</span>
            </div>
            <div>
                <h1>
                Quran For All Academy Services
                </h1>
            </div>
            <div>
                <p>"Explore our Quranic services at Towards Quran. From Quran Hifz and Nazra to Tajweed and more, we offer comprehensive guidance for your spiritual growth. Join us today and embark on your Quranic journey with confidence.".</p>
            </div>
            </div>
            <div className="row py-3  d-flex justify-content-center">
          <div className="col-md-4">
            <div>
              <img src="/Assets/s1.svg" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>ONLINE QURAN RECITATION</h5>
            </div>
            <div>
              <p>
              Master the art of Quranic recitation from the comfort of your home.
Improve your pronunciation and fluency in Quranic recitation.
Expert guidance for learners of all levels.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img src="/Assets/s2.svg" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>NOORANI QAIDA ONLINE</h5>
            </div>
            <div>
              <p>
              Begin your Quranic learning with Noorani Qaida online.
A foundational course for beginners to establish a strong base in Quranic reading.
Step-by-step lessons to help you read the Quran with confidence.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img src="/Assets/s3.svg" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>ONLINE QURAN LEARNING</h5>
            </div>
            <div>
              <p>
              Explore a wide range of Quranic courses tailored to your needs.
Flexible online classes for Quranic studies.
Unlock the treasures of Islamic knowledge from anywhere in the world.
              </p>
            </div>
          </div>
        </div>
        <div className="row py-3  d-flex justify-content-center">
          <div className="col-md-4">
            <div>
              <img src="/Assets/s4.svg" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>ONLINE HiFZ QURAN</h5>
            </div>
            <div>
              <p>
              Embark on your journey to memorize the Quran with our online Hifz program.
Dedicated support and structured lessons for memorization.
Achieve the spiritual milestone of Hifz with our experienced instructors.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img src="/Assets/s5.svg" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>QURAN WITH TAJWEED</h5>
            </div>
            <div>
              <p>
              Enhance your Quranic reading with proper Tajweed rules.
Learn the rules of pronunciation and articulation to recite with beauty and precision.
Elevate your understanding and appreciation of the Quran.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img src="/Assets/s6.svg" alt="circle" />
            </div>{" "}
            <br />
            <div>
              <h5>FEMALE QURAN TUTOR</h5>
            </div>
            <div>
              <p>
              Experience the guidance and mentorship of our qualified female Quran tutors.
A comfortable and supportive learning environment for female students.
Empowering women to connect deeply with the Quranic teachings.
              </p>
            </div>
          </div>
        </div>
        </div>
        
    </div>
  )
}

export default Services