import React from 'react'

function Learning() {
  return (
    <div className='learning'>
        <div className="container py-4">
            <div className="row">
                <div className='col-md-3 quran '>
                <div className="ab">
              <span>ONLINE QURAN ACADEMY</span>
            </div>
            <div>
              <h2>Lets Start Online
Quran Learning</h2>
            </div>
           
            <br />
            <div className='d-flex gap-4 '>
                <div>
                <button className="btn btn1 contact">START FREE TRIAL</button>
                </div>
            </div>
                </div>
                
            </div>
        </div>
        
    </div>
  )
}

export default Learning