import React from "react";
import {
  BiLogoFacebookCircle,
  BiLogoTwitter,
  BiLogoYoutube,
} from "react-icons/bi";
import {BsInstagram} from "react-icons/bs"
import { CiLocationOn } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { AiOutlineClockCircle } from "react-icons/ai";
function Footer() {
  const openFacebookTab = () => {
    const url = "https://www.facebook.com/freeonlinequran?mibextid=ZbWKwL";
    window.open(url, "_blank");
  };
  const openTwitterTab = () => {
    const url = "https://tally.so/r/3xj7lk";
    window.open(url, "_blank");
  };
  const openInstagramTab = () => {
    const url = "https://www.instagram.com/towards__quran/?igshid=NjIwNzIyMDk2Mg%3D%3D";
    window.open(url, "_blank");
  };
  return (
    <div className="footer">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-2">
            <div className="mt-5">
              <p >
              You can contact us on Facebook, Instagram WhatsApp Or directly send us Queries on our phone number Or Email.
              </p>
            </div>
            <div className="d-flex gap-2">
              <div>
                <BiLogoFacebookCircle size={"30px"} color="#157815" style={{cursor:"pointer"}} title="Facebook" onClick={openFacebookTab} />
              </div>
              <div>
                <BiLogoTwitter size={"30px"} color="#157815" style={{cursor:"pointer"}} title="Twitter" />
              </div>
              <div>
                <BsInstagram size={"25px"} color="#157815" onClick={openInstagramTab} style={{cursor:"pointer"}} title="Instagram" />
              </div>
             
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <h4>Academy Services</h4>
              <img src="Assets/divider.png" alt="divider" />
              <ul className="styled-list" style={{ lineHeight: 2 }}>
                <li>Quran Recitation</li>
                <li>Noorani Qaida</li>
                <li>Quran With Tajweed</li>
                <li>Quran Translation</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <h4>Contact Info</h4>
              <img src="Assets/divider.png" alt="divider" />
              <ul className="list-unstyled" style={{ lineHeight: 3 }}>
                <li>
                  <CiLocationOn size={"22px"} color="#157815" />
                  &nbsp;10365 Falcon Point Way Manassas, VA, 20110
                </li>
                {/* <li>
                  <BsTelephone size={"22px"} color="#157815" />
                  &nbsp; (+1) 111-1234567
                </li> */}
                <li>
                  <CiMail size={"22px"} color="#157815" />
                  &nbsp; towardsquran001@gmail.com
                </li>
                <li>
                  <AiOutlineClockCircle size={"22px"} color="#157815" /> &nbsp;
                  Mon - Sat 09:00 - 17:00
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <h4>Subscribe Now</h4>
              <img src="Assets/divider.png" alt="divider" />
              <div>
                <input
                  type="email"
                  className="p-4"
                  placeholder="Enter Your Email Here"
                />
              </div>
              <br />
              <div>
                <button className="btn  p-3">Subscribe Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 navbottom text-center">
        <p>Copyright © 2023. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
