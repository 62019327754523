import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  
  return (
    <div className='header'>
    <nav className="navbar navbar-expand-lg bgnav">
  <div className="container-fluid">
   <div>
   <a className="nav-link active" aria-current="page" href="#hero"> <img
              src="/Assets/logo.svg"
              alt="quran"
              className="img-fluid"
              style={{width:"150px"}}
            /></a>
   </div>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse justify-content-end mx-2" id="navbarSupportedContent">
      <ul className="navbar-nav mb-2 mb-lg-0">
        {/* <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#hero">Home</a>
        </li> */}
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#abouts">About Us</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Services
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/">Quran Recitation</a></li>
            <li><a className="dropdown-item" href="/">Quran With Tajweed</a></li>
            {/* <li><hr className="dropdown-divider"/></li> */}
            <li><a className="dropdown-item" href="/">Quran Hifz</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#fees">Fee</a>
        </li>
        <li className="nav-item">
          <Link to='/download'>
          <a className="nav-link active" aria-current="page" >
            Download
            </a>
          </Link>
        </li>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#blog">Blogs</a>
        </li>
        <li className="nav-item">
          <Link to='/contactus'>
          <a className="nav-link active" aria-current="page" >Contact</a>
          </Link>
        </li>
      </ul>
     
    </div>
    
  </div>
</nav>        
    </div>
  )
}

export default Header