import React from "react";
import { MdMail } from "react-icons/md";
import {MdLocationPin} from "react-icons/md";

function Topnav() {
  
 

  return (
    <div className="topnav">
      <div className="container-fluid">
        <div className="row">
            <div className=" d-flex justify-content-between topn">
          <div>
            <img
              src="/Assets/logo.svg"
              alt="quran"
              className="img-fluid"
              style={{width:"200px"}}
            />
          </div>
          <div className="d-flex mt-3 gap-4 topn">
            <div className="d-flex gap-2">
              <div className="mt-1">
                <MdMail  size={"40px"} color="#157815"/>
              </div>
              <div style={{lineHeight:"1"}}>
                {/* <h6>(+1) 111-1234567</h6> */}
                <h6 className="pt-3">  towardsquran001@gmail.com</h6>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div className="mt-1">
                <MdLocationPin  size={"40px"} color="#157815"/>
              </div>
              <div style={{lineHeight:"1"}}>
                <h6>10365 Falcon Point <br />  Way Manassas, VA, 20110</h6>
                {/* <p> &nbsp;Jamaica, NY 11434</p> */}
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topnav;
