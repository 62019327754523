import React from "react";
import { MdOutlineDateRange } from "react-icons/md";
import { BiSolidMessageRounded } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Data from "../Utils/Quranblog.json"
function Blog() {
  const navigate = useNavigate()
  const handleBlog = (item)=>{
    navigate('/blogpage',{state:item})
  }
  return (
    <div className="blog" id="blog">
      <div className="container py-5">
        <div className="row text-center py-3">
          <div className="ab">
            <span>OUR BLOG</span>
          </div>
          <div>
            <h1>Quran For All Academy Services</h1>
          </div>
          <div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
              Ut enim ad minim veniam, quis nostrud.
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          {
            Data.map((item)=>(
              <div className="col-md-4 mb-3">
            <div class="card " key={item.id} onClick={()=>handleBlog(item)}>
              <img src={item.image} class="img-fluid" alt="blog1" />
              <div class="card-body">
                <p class="card-subtitle mb-2 text-body-secondary d-flex gap-3">
                  <span>
                    <MdOutlineDateRange /> February 26, 2022
                  </span>
                  <span>
                    <BiSolidMessageRounded /> 0
                  </span>
                </p>
                <h5 class="card-title">{item.title}</h5>
                <p class="card-text">
                  {(item.description).split(' ').slice(0, 20).join(' ')}.......
                </p>
               
              </div>
              <div className="card-footer">
              <button className="btn read" onClick={()=>handleBlog(item)}>
                  <BsArrowRight /> Read more
                </button>
              </div>
            </div>
          </div>
            ))
          }
          {/* <div className="col-md-4 mb-3">
            <div class="card">
              <img src="/Assets/b2.png" class="img-fluid" alt="blog1" />
              <div class="card-body">
                <p class="card-subtitle mb-2 text-body-secondary d-flex gap-3">
                  <span>
                    <MdOutlineDateRange /> February 26, 2022
                  </span>
                  <span>
                    <BiSolidMessageRounded /> 0
                  </span>
                </p>
                <h5 class="card-title">
                  What Are The Benefits Of Reciting The…
                </h5>
                <p class="card-text">
                  What Are The Benefits Of Reciting The Quran? Home / Quran What
                  Are The Benefits…
                </p>
               
              </div>
              <div className="card-footer">
              <button className="btn read" onClick={handleBlog}>
                  <BsArrowRight /> Read more
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div class="card">
              <img src="/Assets/b3.png" class="img-fluid" alt="blog1" />
              <div class="card-body">
                <p class="card-subtitle mb-2 text-body-secondary d-flex gap-3">
                  <span>
                    <MdOutlineDateRange /> February 26, 2022
                  </span>
                  <span>
                    <BiSolidMessageRounded /> 0
                  </span>
                </p>
                <h5 class="card-title">
                  Connecting To Allah Through The Quran
                </h5>
                <p class="card-text">
                  Connecting To Allah Through The Quran Home / Quran Connecting
                  To Allah Through The Quran…
                </p>
                
              </div>
              <div className="card-footer">
              <button className="btn read" onClick={handleBlog}>
                  <BsArrowRight /> Read more
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Blog;
